import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLanguage } from './LanguageContext';
import VideoList from './VideoList';
import CustomTextField from './CustomTextField';
import PasteIcon from '@mui/icons-material/ContentPasteOutlined';
import DownnIcon from '@mui/icons-material/DownloadOutlined';
import Carousel from './Carousel'; // Adjust the path to where you saved the Carousel component

import { Box, LinearProgress, Typography, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, InputAdornment, Button } from '@mui/material';
import RateDialog from './RateDialog';

const VideoDownloadForm = ({ isMobile , shareData }) => {
  const [url, setUrl] = useState('');
  const [orginUrl, setOrginUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { language } = useLanguage();
  const [videoData, setVideoData] = useState([]);
  const [videoCover, setVideoCover] = useState("");
  const [clipboardContent, setClipboardContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isProssess, setIsProssess] = useState(false);
  const [showRateDialog, setShowRateDialog] = useState(false);

  const handleRateDialogClose = (data) => {
    // Mark the rate dialog as shown
    console.log('Response from API2:', data);
    if(data.success){
      localStorage.setItem('rateDialogShown', 'true');
      setShowRateDialog(false);
    } else {
      setShowRateDialog(false);      
    }
  };
  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%_.~+@]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-@]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return !!urlPattern.test(url);
  };
  

  const isBlockedeUrl = (url) => {
    return false;//url.includes('youtube.com') || url.includes('youtu.be');
  };
  useEffect(() => {
    // Check if the rate dialog has been shown before
    const rateDialogShown = localStorage.getItem('rateDialogShown');
    if (!rateDialogShown) {
      setShowRateDialog(true);
    }
  }, []);

  const handleDownload = async () => {
    if (isBlockedeUrl(url)) {
      setDialogOpen(true);
      return;
    }

    if (!validateUrl(url)) {
      setError(language === 'ar' ? 'الرابط غير صالح' : 'Invalid URL');
      return;
    }
    setLoading(true);
    setVideoData([]); // Clear the previous video data
    setVideoCover("");
    setError('');
    try {
      const response = await axios.post('https://samrt-loader.com/kydwon/api/addfile', { file_name: url });
      if (response.data.success) {
        if(response.data.task_id !== null) {
          checkStatus(response.data.task_id)
        } else {
          setVideoData(response.data.files); // Set videoData state with response data
          setVideoCover(response.data.image_cover);
          setOrginUrl(response.data.file_url);
          setLoading(false);
        }
      } else {
        setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video.');
        setLoading(false);
      }
    } catch (error) {
      setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video.');
      setLoading(false);
    }
  };

  const checkStatus = async (taskId) => {
    const statusResponse = await fetch(`https://samrt-loader.com/task_status_down/${taskId}`);
    const statusData = await statusResponse.json();

    if (statusData.status === 'PENDING') {
      setIsProssess(true);
      setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
    } else if (statusData.status === 'SUCCESS') {
      setVideoData(statusData.files); // Set videoData state with response data
      setVideoCover(statusData.image_cover);
      setOrginUrl(statusData.file_url);
      setLoading(false);
      setIsProssess(false);
      //console.error('success path', url);
    } else {
      setError(language === 'ar' ? 'لم يتم العثور على فيديو' : 'Failed to download video .');
      setLoading(false);
      setIsProssess(false);
    }
  };

  const handlePasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const trimmedText = text.replace(/\s/g, ''); // Remove all spaces
      setClipboardContent(trimmedText);
      setUrl(trimmedText); // Update the URL field with the trimmed clipboard content
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleClearInput = () => {
    setUrl('');
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      {showRateDialog && videoData.length > 0 && <RateDialog onClose={handleRateDialogClose} />}
      <Box mt={4} mx={2}>
        {shareData.length > 0 && (
           <Carousel data={shareData} />
        )}
        <Card variant="outlined" sx={{ boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)', borderRadius: '30px' }}>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={4} my={3}>
              <CustomTextField
                label="https://"
                variant="outlined"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
                margin="normal"
                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="Paste from clipboard" onClick={handlePasteFromClipboard}>
                        <PasteIcon />
                      </IconButton>
                      <IconButton aria-label="Download" onClick={handleDownload} disabled={loading}>
                        <DownnIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Box width="100%" my={2}>
                {loading &&(
                  <div>
                    <LinearProgress />
                     &nbsp;
                    {isProssess ? (
                      <Typography color="rgb(210, 115, 52)" >{language === 'ar' ? 'جار معالجة الفيديو قد يستغرق وقتا أطول ...' : "Processing video , It may take a while ..."}</Typography>
                    ) : (
                      <Typography color="rgb(210, 115, 52)" >{language === 'ar' ? 'جار البحث , لاتحدث الصفحة ...' : "Serching video , Don't refresh ..."}</Typography>
                    )}
                  </div>
                )}
                {error && <Typography color="error">{error}</Typography>}
              </Box>
              <Box width="100%" my={2}>
                <VideoList videoData={videoData} isMobile={isMobile} videoCover={videoCover} orginUrl={orginUrl} isEditer={false}/> {/* Pass videoData to VideoList */}
              </Box>
            </Box>
            <Box width="100%" my={2}>
              <p style={{color:'burlywood'}}>Powered by Smart Loader</p>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Dialog for YouTube URL */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{language === 'ar' ? 'رابط محظور' : 'Blocked Url'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language === 'ar' ? 'لا يمكن تنزيل الفيديوهات من يوتيوب إتباعا لسياسة الخصوصية.' : 'Videos from YouTube cannot be downloaded for Privacy Police.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoDownloadForm;
