// src/components/Navbar.js
import React, { useState , useEffect } from 'react';
import { Box,Badge, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, AppBar, Toolbar, Typography, Button, ButtonGroup, IconButton, Menu, MenuItem, Link, CircularProgress } from '@mui/material';
import { useLanguage } from './LanguageContext';
import { useTheme } from './ThemeContext';
import { updateClick } from './updateClick';
import MenuIcon from '@mui/icons-material/Menu';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomGoogleLogin from './CustomGoogleLogin';
import { useLoading } from './LoadingContext';
import useSweetAlert from './SweetAlert';
import { jwtDecode } from 'jwt-decode';
import CircularImageWithButton from './CircularImageWithButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import IosShare from '@mui/icons-material/IosShare';
import RocketLaunchRounded from '@mui/icons-material/RocketLaunchRounded';
import Share from '@mui/icons-material/Share';
import LoginRounded from '@mui/icons-material/LoginRounded';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ModeNightIcon from '@mui/icons-material/Bedtime';
import LogoutIcon from '@mui/icons-material/Logout';
import './downloads.css';

const Navbar = () => {
    const { showAlert } = useSweetAlert();
    const { language, toggleLanguage } = useLanguage();
    const { theme, toggleTheme } = useTheme();
    const [isLoading, setIsLoading] = useState(false); // Add a loading state
    const [isLoadAccount, setLoadAccount] = useState(false); // Add a loading state
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openReg, setOpenReg] = useState(false);
    const [openChangePass, setOpenChangePass] = useState(false);
    const [openAccount, setOpenAccount] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [name, setName] = useState('');
    const [userPhotoURL, setUserPhotoURL] = useState(() => {
      const storedUserPhotoUrl = localStorage.getItem('user_photo_url');
      return storedUserPhotoUrl || '';  // If null, return an empty string
    });
    const [twiterAccount, settwiterAccount] = useState('');
    const [snapAccount, setSnapAccount] = useState('');
    const [tiktokAccount, setTiktokAccount] = useState('');
    const [instaAccount, setInstaAccount] = useState('');
    const [facebookAccount, setFacebookAccount] = useState('');
    const isPass = localStorage.getItem('is_set_pass') === 'true' || false;
    const [showNewShare, setShowNewShare] = useState(false);
    const [shareCount, setShareCount] = useState(0);
    const [newShareCount, setNewShareCount] = useState(0);
    const [showOurProductsDialog, setShowOurProductsDialog] = useState(false);
    const [showReadProductDialog, setShowReadProductDialog] = useState(false);
    const [readeadProductType, setReadeadProductType] = useState('');

    useEffect(() => {
      const shareCounter = localStorage.getItem('share_count');
      const currentShareCount = localStorage.getItem('current_share_count');
      setShareCount(shareCounter);
      if(shareCounter > 0){
        if (shareCounter !== currentShareCount) {
          const newShare = shareCounter - currentShareCount;
          setNewShareCount(newShare);
          setShowNewShare(true);
        }
      }
    })

    const handleDownloader = () => {
      //navigate('./');
      window.location.href = './downloads';
    }

    function notificationsLabel(count) {
      if (count === 0) {
        return 'no notifications';
      }
      if (count > 99) {
        return 'more than 99 notifications';
      }
      return `${count} notifications`;
    }

    //const [IsLogin, setIsLogin] = useState(false);
    const [IsLogin, setIsLogin] = useState(() => {
      // Attempt to load the user from localStorage
      const checkIsLogin = localStorage.getItem('isLogin');
      if (checkIsLogin === "true") {
        // Optionally decode token to get user info
        return true;
      }
      return false;
    });
    const [open, setOpen] = useState(false);
    const [openDrower, setOpenDrower] = useState(false);

    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  
    const toggleDrawer =
      (anchor, open) =>
      (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }
  
        setState({ ...state, [anchor]: open });
      };
  
    const list = (anchor) => (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
            <ListItem disablePadding>
            {IsLogin ? (
              <ListItemButton onClick={handleClickOpenAccount}>
                <ListItemIcon>
                  {userPhotoURL === '' ? (
                    <AccountCircle />
                  ) : (
                    <img src={userPhotoURL} style={{borderRadius:'50%',objectFit:'cover'}} alt="user photo" width={'30px'} height={'30px'}/>
                  )}
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'الحساب' : 'Account'} />
              </ListItemButton>
            ) : (
              <ListItemButton onClick={handleClickOpen}>
              <ListItemIcon>
                <LoginRounded />
              </ListItemIcon>
              <ListItemText primary={language === 'ar' ? 'تسجيل الدخول' : 'Login'} />
            </ListItemButton>
            )}
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleCustumEditer}>
                <ListItemIcon>
                  <MovieCreationIcon />
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'محرر الفيديوهات' : 'Videos Editer'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleDownloader}>
                <ListItemIcon>
                  <FileDownloadIcon />
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'تحميل الفيديوهات' : 'Videos Dwonloader'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleBoxShare}>
                <ListItemIcon>
                  {showNewShare ? (
                    <IconButton aria-label={notificationsLabel(newShareCount)}>
                      <Badge badgeContent={newShareCount} color="secondary">
                        <IosShare />
                      </Badge>
                    </IconButton>
                  ) : (
                    <IosShare />
                  )} 
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'صندوق المشاركات' : 'Share Box'} />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
        <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleOurProducts}>
                <ListItemIcon>
                  <RocketLaunchRounded />
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'منتجاتنا' : 'Our Products'} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={shareContent}>
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                <ListItemText primary={language === 'ar' ? 'مشاركة التطبيق' : 'Share App'} />
              </ListItemButton>
            </ListItem>
        </List>
      </Box>
    );
  
    const [openAccounts, setOpenAccounts] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confrormNewPassword, setConformNewPassword] = useState('');
    const { showLoading, hideLoading } = useLoading();
    //const { JWTToken ,login , logout } = useAuth();

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const openReadProductModel = (type) => {
      setReadeadProductType(type);
      setShowReadProductDialog(true);
    };

    const handleCloseReadProductDialog = () => {
      setShowReadProductDialog(false);
    }
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleCloseOurProductsDialog = () => {
      setShowOurProductsDialog(false);
    };

    const handleCustumEditer = () => {
      handleMenuClose();
      //showLoading();
      //navigate(`./editer`); 
      window.location.href = './editer'      
    };

    const handleOurProducts = () => {
      handleMenuClose();
      setShowOurProductsDialog(true);
    }

    const handleBoxShare = () => {
      handleMenuClose();
      localStorage.setItem("current_share_count",shareCount);
      window.location.href = './ShareBox'      
    };

    const goToProductSite = async(type) => {
      if(type === 'maoread'){
        window.open('https://play.google.com/store/apps/details?id=com.webapp.maoread', '_blank');
      } else {
        window.open('https://vpn366.com', '_blank');
      }
      setShowReadProductDialog(false);
      await updateClick(`open_product_${type}`);
    }
  
    const handleClickOpen = () => {
      handleMenuClose();
      setOpen(true);
    };
    

    const handleClickOpenAccounts = () => {
      setOpenAccounts(true);
    };

    const handleClickOpenAccount = async () => {
      handleMenuClose();
      setLoadAccount(true);
      setOpenAccount(true);
    
      try {
        const userID = localStorage.getItem('userid');
        const response = await fetch(`https://samrt-loader.com/kydwon/api/view/users/${userID}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
    
        const data = await response.json();
    
        if (!data.success) throw new Error('Failed to load account data');
    
        setUserEmail(data.users.use_email);
        const userPhoto = data.users.user_photo?.url || data.users.user_google_photo_url || 'https://samrt-loader.com/logo192.png';
        setUserPhotoURL(userPhoto);
        if(data.users.user_name){
          setName(data.users.user_name);
        }
        if(data.users.facebook_account){
          setFacebookAccount(data.users.facebook_account);
        }
        if(data.users.insta_account){
          setInstaAccount(data.users.insta_account);
        }
        if(data.users.twiter_account){
          settwiterAccount(data.users.twiter_account);
        }
        if(data.users.snap_account){
          setSnapAccount(data.users.snap_account);
        }
        if(data.users.tiktok_account){
          setTiktokAccount(data.users.tiktok_account);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
    
        showAlert({
          text: language === 'ar' ? 
            'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
            'An error occurred while getting your account data, Please try login again',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start',
        });
    
        handleCloseAccount();
        setIsLogin(false);
        localStorage.setItem('authToken', null);
        localStorage.setItem('isLogin', 'false');
        localStorage.setItem('userid', null);
        localStorage.setItem('username', null);
        localStorage.setItem('userlevelid', null);    
      } finally {
        setLoadAccount(false);
      }
    };

    const handleUpload = async (file) => {
      showLoading();
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
         // alert('Image uploaded successfully!');
          console.log('Uploaded data:', data);
          if(data.success){
            try {
              const fileToken = data.filetoken;
              const formData = new FormData();
              formData.append('user_photo', fileToken);
              const userID = localStorage.getItem('userid');
              const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/users/${userID}`, {
                method: 'POST',
                headers: {
                  'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                },
                body: formData,
              });
              const data2 = await response2.json();
              console.log('Uploaded data:', data2);
              if(data2.success) {
                setUserPhotoURL(data2.users.user_photo.url);
                showAlert({
                  text: language === 'ar' ? 'تم التعديل بنجاح' : 'Edit success',
                  icon: 'success',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
              }
            } catch (error) {
              console.error('Error uploading image:', error);
              showAlert({
                text: language === 'ar' ? 'حدث خطأ أثناء التعديل, الرجاء المحاولة لاحقا.' : 'Error uploading image, Please try again later.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          }
        } else {
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء التعديل, الرجاء المحاولة لاحقا.' : 'Error uploading image, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء التعديل, الرجاء المحاولة لاحقا.' : 'Error uploading image, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      }
      hideLoading();
    };

    const handleClickOpenReg = () => {
      setOpen(false);
      setOpenReg(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleCloseReg = () => {
      setOpenReg(false);
    };

    const handleCloseAccounts = () => {
      setOpenAccounts(false);
    };

    const handleCloseAccount = () => {
      setOpenAccount(false);
    };

    const handleCloseChangePass = () => {
      setOpenChangePass(false);
    };

    const handleOpenChangePass = () => {
      setOpenChangePass(true);
    };

    const handleTwiterChange = (e) => {
      settwiterAccount(e.target.value);
    };

    const handleSnapChange = (e) => {
      setSnapAccount(e.target.value);
    };

    const handleFacebookChange = (e) => {
      setFacebookAccount(e.target.value);
    };

    const handleTikTokChange = (e) => {
      setTiktokAccount(e.target.value);
    };

    const handleInstaChange = (e) => {
      setInstaAccount(e.target.value);
    };

    const handleEmailChange = (e) => {
      const email = e.target.value;
      setEmail(email);
      if(email === '') {
        setIsValid(true);
      } else {
        setIsValid(isValidEmail(email));
      }
    };

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const handleNameChange = (e) => {
      setName(e.target.value);
    };

    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };

    const handleCurrentPasswordChange = (e) => {
      setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
      setNewPassword(e.target.value);
    };

    const handleConformNewPasswordChange = (e) => {
      setConformNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
    };
  
    const handleChangePass = async() => {
      if(isPass){
        if (currentPassword === '' || newPassword === '' || confrormNewPassword === '') {
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال جميع الحقول' : 'Please enter all feialds',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
      } else {
        if (newPassword === '' || confrormNewPassword === '') {
          showAlert({
            text: language === 'ar' ? 'الرجاء إدخال جميع الحقول' : 'Please enter all feialds',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          return;
        }
      }
      if (newPassword !== confrormNewPassword) {
        //alert("Passwords don't match!");
        showAlert({
          text: language === 'ar' ? 'كلمة المرور غير متطابفة' : 'Passwords not match!',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
      try{
        showLoading();
        var done = false ;
        const formData = new FormData();
        formData.append('old_pass', currentPassword);
        formData.append('new_pass', newPassword);
        formData.append('is_set_pass', isPass);
        const response = await fetch('https://samrt-loader.com/kydwon/api/changepass' , {
          method: 'POST', // Specify the method
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData
        });
        const data = await response.json();
        if(data.success) {
          done = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء التعديل, الرجاء الحاولة لاحقا' : 'An error while changing password, Please try again later',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
        if(done){
          localStorage.setItem('is_set_pass','true');
          handleCloseChangePass();
          showAlert({
            text: language === 'ar' ? 'تم التحديث بنجاح' : 'Password change successful',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
          //window.location.reload();
        }
      }
    };

    const handleRegister = async() => {
      if (password !== confirmPassword) {
        //alert("Passwords don't match!");
        showAlert({
          text: language === 'ar' ? 'كلمة المرور غير متطابفة' : 'Passwords not match!',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      } 
      if (name === '' || email === '' || password === '' || confirmPassword === '') {
        showAlert({
          text: language === 'ar' ? 'الرجاء إدخال جميع الحقول' : 'Please enter all feialds',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
      if(!isValid){
        showAlert({
          text: language === 'ar' ? 'الرجاء إدخال صيغة الإيميل صحيحة' : 'Please enter a valid e-mail value',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
      //console.log(`Email: ${email}, Password: ${password}`);
      //handleCloseReg();
      showLoading();
      var done = false;
      // Simulate API call
      try{
        const response = await fetch('https://samrt-loader.com/kydwon/api/register', {
          method: 'POST', // Specify the method
          headers: {
            'Content-Type': 'application/json' // Set the Content-Type header
          },
          body: JSON.stringify({
            user_name: name, // Use the correct field names
            use_email: email, // Use the correct field names
            password: password
          })
        });
        const data = await response.json();
        if(data.success) {
          done = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء التسجيل, الرجاء الحاولة لاحقا' : 'An error while register, Please try again later',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        hideLoading();
      } finally {
        hideLoading();
        if(done){
          handleCloseReg();
          showAlert({
            text: language === 'ar' ? 'تم تسجيل حسابك بنجاح برجاء تفعيل الحساب من خلال الإيميل المرسل' : 'Registration success, Please active your account throw sending email.',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        } else {
          showAlert({
            text: language === 'ar' ? 'لم يتم التسجيل بنجاح' : 'Registration not success',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      }
    };

    const showErrorLogin = () => {
      showAlert({
        text: language === 'ar' ? 'الإيميل أو كلمة المرور خاطئة' : 'Wrong E-mail or password',
        icon: 'error',
        position: language === 'ar' ? 'top-end' : 'top-start'
      });
      hideLoading();
    }

    const logout = async() => {
      setIsLogin(false);
      localStorage.setItem('authToken', null);
      localStorage.setItem('isLogin', 'false');
      localStorage.setItem('userid', null);
      localStorage.setItem('username', null);
      localStorage.setItem('userlevelid', null);
      localStorage.setItem('project_id',"0");
      window.location.reload();
    }

    const handleSaveAccount = async () => {
      try {
        //handleClose();
        showLoading();
        var done = false;
        // Simulate API call
        const formData = new FormData();
        formData.append('user_name', name);
        formData.append('facebook_account', facebookAccount);
        formData.append('insta_account', instaAccount);
        formData.append('twiter_account', twiterAccount);
        formData.append('snap_account', snapAccount);
        formData.append('tiktok_account', tiktokAccount);
        const userID = localStorage.getItem('userid');
        const response = await fetch(`https://samrt-loader.com/kydwon/api/edit/users/${userID}` , {
          method: 'POST', // Specify the method
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body:formData
        });

        const data = await response.json();
        if(data.success){
          done = true;
          if(data.users.user_name){
            setName(data.users.user_name);
          }
          if(data.users.facebook_account){
            setFacebookAccount(data.users.facebook_account);
          }
          if(data.users.insta_account){
            setInstaAccount(data.users.insta_account);
          }
          if(data.users.twiter_account){
            settwiterAccount(data.users.twiter_account);
          }
          if(data.users.snap_account){
            setSnapAccount(data.users.snap_account);
          }
          if(data.users.tiktok_account){
            setTiktokAccount(data.users.tiktok_account);
          }
        }
        //console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        showAlert({
          text: language === 'ar' ? 'لم يتم تعديل الحساب بنجاح' : 'Update account not success',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
        if(done){
          handleCloseAccounts();
          showAlert({
            text: language === 'ar' ? 'تم الحفظ بنجاح' : 'Save successful',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      }
    }

    const handleLogin = async () => {
      if(!isValid){
        showAlert({
          text: language === 'ar' ? 'الرجاء إدخال صيغة الإيميل صحيحة' : 'Please enter a valid e-mail value',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
      if (email === '' || password === '') {
        showAlert({
          text: language === 'ar' ? 'الرجاء إدخال الإيميل وكلمة المرور' : 'Please enter your e-mail and password',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
      try {
        //handleClose();
        showLoading();
        setIsLogin(false);
        var done = false;
        // Simulate API call
        const formData = new FormData();
        formData.append('username', email);    
        formData.append('password', password);

        const response = await fetch('https://samrt-loader.com/kydwon/api/login', {
          method: 'POST', // Specify the method
          body: formData
        });

        const data = await response.json();
        const JWT = data.JWT;
        if(JWT){
          localStorage.setItem('authToken', JWT);
          localStorage.setItem('isLogin', 'true');
          const decodedToken = jwtDecode(JWT);
          const userid = decodedToken.security.userid;
          const username = decodedToken.security.username;
          const userLevelid = decodedToken.security.userlevelid;
          localStorage.setItem('userid', userid)
          localStorage.setItem('username', username)
          localStorage.setItem('userlevelid', userLevelid)
          localStorage.setItem('project_id',"0")
          setIsLogin(true);
          done = true;
        }
        //console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        showErrorLogin();
      } finally {
        if(done){
          hideLoading();
          handleClose();
          window.location.reload();
        } else {
          showErrorLogin();
        }
      }
    };

    const shareContent = async () => {
        showLoading();
        await updateClick('share');
        if (navigator.share) {
           const textSet = language === 'ar' ? 'جرب Smart Loader لتنزيل مقاطع فيديو من سوشيال ميديا' : 'Try out Smart Loader for social media videos download'
          try {
            hideLoading();
            await navigator.share({
              title: 'Smart Loader',
              text: [textSet],
              url: 'https://samrt-loader.com',
            });
            console.log('Content shared successfully');
          } catch (error) {
            hideLoading();
            console.error('Error sharing content: ', error);
          }
        } else {
          hideLoading();
          console.log('Web Share API is not supported in your browser.');
        }
      };
    return (
        <AppBar className='homepage-header-app' position="relative">
            <Toolbar>
                <Link href="../../"  className="animated-link" style={{marginTop:'5px'}}>
                  <img src="../../smartloader.webp" width={30} height={30} alt="Smart Loader" />
                </Link>
                &nbsp;
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {language === 'ar' ? 'التحميل الذكي' : 'Smart Loader'}
                </Typography>
                <Button aria-label="lang" color="inherit" onClick={toggleLanguage}>
                    {language === 'ar' ? 'EN' : 'AR'}
                </Button>
                <Button aria-label="theme" color="inherit" onClick={toggleTheme}>
                    {theme === 'light' ? 
                      <ModeNightIcon style={{fontSize:'medium'}}/> : 
                      <WbSunnyIcon style={{fontSize:'medium'}}/>
                    }
                </Button>
                <IconButton
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer('right', true)}
        >
          <MenuIcon />
        </IconButton>

      <SwipeableDrawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
          >
            {list('right')}
          </SwipeableDrawer>
        <Dialog open={open} onClose={handleClose} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'تسجيل الدخول' : 'Login'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
          {language === 'ar' ? 'الرجاء إدخال الإيميل وكلمة المرور , أو المتابعة عبر حساب جوجل لتسجيل الدخول.' : 'Please enter your email and password, or continue with your Google account.'}
            
          </DialogContentText>
          <div>
          <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'الإيميل' : 'Email Address'}
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
          />
          {!isValid && <p style={{ color: 'red' ,fontSize:'12px'}}>{language === 'ar' ? "الإيميل غير صالح" : "Invalid email address"}</p>}
          </div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور' : 'Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
          />
          <h3 style={{textAlign:'center'}}>- {language === 'ar' ? 'أو' : 'OR'} -</h3>
          <Button
            fullWidth
            variant="outlined"
            color='info'
            onClick={handleClickOpenReg}
            style={{marginBottom:'8px'}}
          >
              {language === 'ar' ? 'تسجيل حساب جديد' : 'Register new account'}
          </Button>
          <GoogleOAuthProvider clientId="79586729060-s2fhal0cmcnqhdvg9dhkei9f0fr4esvg.apps.googleusercontent.com">
            <CustomGoogleLogin
              lang={language}
            />
          </GoogleOAuthProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button onClick={handleLogin} color="success">            
            {language === 'ar' ? 'تسجيل الدخول' : 'Login'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAccounts} onClose={handleCloseAccounts} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حساباتي' : 'My accounts'}</DialogTitle>
        <DialogContent>
        <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'إسم المستخدم' : 'User name'}
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
        <TextField
            margin="dense"
            label={language === 'ar' ? 'حساب تويتر' : 'twiter account'}
            type="text"
            fullWidth
            variant="outlined"
            value={twiterAccount}
            onChange={handleTwiterChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'حساب إنستجرام' : 'instgram account'}
            type="text"
            fullWidth
            variant="outlined"
            value={instaAccount}
            onChange={handleInstaChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'حساب سناب شات' : 'Snapchat account'}
            type="text"
            fullWidth
            variant="outlined"
            value={snapAccount}
            onChange={handleSnapChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'حساب تيك توك' : 'TikTok account'}
            type="text"
            fullWidth
            variant="outlined"
            value={tiktokAccount}
            onChange={handleTikTokChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'حساب فيس بوك' : 'facebook account'}
            type="text"
            fullWidth
            variant="outlined"
            value={facebookAccount}
            onChange={handleFacebookChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccounts} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button onClick={handleSaveAccount} color="success">            
            {language === 'ar' ? 'حفظ' : 'Change'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChangePass} onClose={handleCloseChangePass} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'تغيير كلمة المرور' : 'Change password'}</DialogTitle>
        <DialogContent>
          {isPass ? (
            <div>
            <TextField
              autoFocus
              margin="dense"
              label={language === 'ar' ? 'كلمة المرور الحالية' : 'Current password'}
              type="password"
              fullWidth
              variant="outlined"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
            />
            <TextField
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور الجديدة' : 'New password'}
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'تأكيد كلمة المرور' : 'Conform New password'}
            type="password"
            fullWidth
            variant="outlined"
            value={confrormNewPassword}
            onChange={handleConformNewPasswordChange}
          /></div>
          ) : (
            <div>
            <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور الجديدة' : 'New password'}
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'تأكيد كلمة المرور' : 'Conform New password'}
            type="password"
            fullWidth
            variant="outlined"
            value={confrormNewPassword}
            onChange={handleConformNewPasswordChange}
          /></div>
          )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseChangePass} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"change password"} onClick={handleChangePass} color="success">            
            {language === 'ar' ? 'تغيير' : 'Change'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAccount} onClose={handleCloseAccount} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'الحساب' : 'Account'}</DialogTitle>
        <DialogContent style={{padding:'2px'}}>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'عرض وتعديل معلومات حسابك' : 'View and Edit your account info'}
          </DialogContentText>
          {isLoadAccount ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
          ) : (
            <Box width="100%" my={5} alignItems={'center'} textAlign={'center'}>
              <CircularImageWithButton
                src={userPhotoURL}
                alt="Profile Picture"
                onUpload={handleUpload}
              />
              <h3>{ name }</h3>
              <p>{ userEmail }</p>
              <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <ButtonGroup variant="contained" color="info" aria-label="outlined button group">
                  <Button onClick={handleClickOpenAccounts} aria-label={"user_soctial_media"} variant="contained" color="primary" style={{fontSize:'small'}}>
                      {language === 'ar' ? 'حساباتي' : 'Account'}
                  </Button> 
                  <Button onClick={handleOpenChangePass} aria-label={"change_password"} variant="contained" color="primary" style={{fontSize:'small'}}>
                      {language === 'ar' ? 'تغيير كلمة المرور' : 'Change pass'}
                  </Button> 
                  <Button onClick={logout} aria-label={"logout"} variant="contained" color="error">
                      <LogoutIcon style={{fontSize:'medium'}}/>
                  </Button> 
                </ButtonGroup>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"cancel"} onClick={handleCloseAccount} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openReg} onClose={handleCloseReg} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'التسجيل' : 'Register'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'الرجاء إدخال بيانات حسابك للتسجيل .' : 'Please enter your data to register a new account'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={language === 'ar' ? 'إسم المستخدم' : 'User name'}
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
          <div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'الإيميل' : 'Email Address'}
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
          />
          {!isValid && <p style={{ color: 'red' ,fontSize:'12px'}}>{language === 'ar' ? "الإيميل غير صالح" : "Invalid email address"}</p>}
          </div>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'كلمة المرور' : 'Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePasswordChange}
          />
          <TextField
            margin="dense"
            label={language === 'ar' ? 'تأكيد كلمة المرور' : 'Confirm Password'}
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseReg} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"register"} onClick={handleRegister} color="success">
            {language === 'ar' ? 'التسجيل' : 'Register'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog How our products */}
      <Dialog open={showOurProductsDialog} onClose={handleCloseOurProductsDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'منتجاتنا' : 'Our products'}</DialogTitle>
        <DialogContent alignContent={'center'} alignItems={'center'}>
        <Box mt={1} my={2} textAlign={'center'}>
            <img src='https://vpn366.com/myvpn/Applogo.webp' alt='vpn 366' height={200} width={200} style={{padding:30}} href={'#'} onClick={() => openReadProductModel('vpn')}/>
            <img src='https://maoread.com/status/Applogo.png' alt='Ma oread' height={200} width={200} href={'#'} onClick={() => openReadProductModel('maoread')}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOurProductsDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog show our product */}
      <Dialog open={showReadProductDialog} onClose={handleCloseReadProductDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض المنتج' : 'Show product'}</DialogTitle>
        <DialogContent alignContent={'center'} alignItems={'center'}>
          <Box mt={1} my={2}>
            {readeadProductType === 'maoread' ? (
              <div>
                <p>{language === 'ar' ? 'تطبيق ماأريد :' : 'Ma oread App :'}</p>
                <p>{language === 'ar' ? 'ماأريد هو تطبيق مجموعة جاهزة من ستوريات فيديوهات صور وكلمات حكم وعبر مااريد شارك حالات مع الأخرين بكل سهولة' : 'Ma oread is to apply a ready-made set of stories, videos, pictures, words of wisdom and proverbs. I want to share statuses with others with ease.'}</p>
              </div>
            ) : readeadProductType === 'vpn' ? (
              <div>
                <p>{language === 'ar' ? 'تطبيق في بي إن 366 :' : 'Vpn 366 App :'}</p>
                <p>{language === 'ar' ? 'هو تطبيق في بي إن بروكسي للتصفح الأمن وإخفاء بيانات إتصالك عبر تشفير حزمة البيناتات المرسلة وإخفاء عنوان الأي بي الخاص بك وفك المواقع والتطبيقات المحظورة وزيادة سرعة إتصالك عبر إستخدام أنفاق تونل , يعمل في جميع الدول العربية .' : 'It is a VPN proxy application for secure browsing and hiding your connection data by encrypting the data packet sent and hiding your IP address and increasing your connection speed by using tunnels. It works in all the Arab countries.'}</p>
              </div>
            ) : (
              <div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReadProductDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Close'}
          </Button>
          <Button onClick={() => goToProductSite(readeadProductType)} color="success">
            {language === 'ar' ? 'الذهاب للموقع' : 'Go to site'}
          </Button>
        </DialogActions>
      </Dialog>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
