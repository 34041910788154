import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const AdsComponent = ({ language }) => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    // Fetch ads from API
    const fetchAds = async () => {
      try {
        const response = await fetch("https://samrt-loader.com/kydwon/api/ads");
        const data = await response.json();
        if (data.ads.length > 0) {
          setAds(data.ads);
          setShowAds(true); // Show ads only if there are ads available
        }
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);

  useEffect(() => {
    if (ads.length > 1) {
      const interval = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 10000); // Change ad every 10 seconds

      return () => clearInterval(interval);
    }
  }, [ads]);

  if (!showAds || ads.length === 0) return null; // Hide if no ads

  return (
    <Box width={"100%"} alignItems={"center"}>
      <img
        style={{ width: "100%", maxWidth: "400px" }}
        alt={ads[currentAdIndex]?.title || "Ad"}
        src={language === "ar" ? ads[currentAdIndex]?.image_ar : ads[currentAdIndex]?.image_en}
        onClick={() => window.open(ads[currentAdIndex]?.link, "_blank")}
      />
    </Box>
  );
};

export default AdsComponent;
